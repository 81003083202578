// stores/userStore.js
import { defineStore } from "pinia";
import axios from "axios";
import Cookies from "js-cookie";
import { extractDomain } from "../helpers";
import { useOrganizationStore } from "./organizationStore";
//const subscriptionStore = useSubscriptionStore();

export const useUserStore = defineStore("user", {
  state: () => ({
    user: Cookies.get("rmeUser429") || { loggedin: false },
    rmeSession: Cookies.get("rmeSession429") || null,
    error: "",
  }),
  getters: {},
  actions: {
    async loginWithEmail(email, pwd) {
      const result = await axios
        .post("/auth/login", {
          username: email.toLowerCase(),
          password: pwd,
        })
        .then((res) => {
          this.user = res.data.user;
          this.rmeSession = res.data.token;
          this.user.loggedin = true;
          posthog.identify(
            this.user._id, // Replace 'distinct_id' with your user's unique identifier
            { email: this.user.email, name: this.user.name } // optional: set additional user properties
          );
          lintrk("setUserData", { email: this.user.email });
          if (window._hsq) {
            _hsq.push([
              "identify",
              {
                email: this.user?.email,
                id: this.user?._id,
                course_count: this.user?.course_count,
              },
            ]);
          }
          const domain = extractDomain(window.location.href);
          Cookies.set("rmeSession429", res.data.token, {
            domain: domain,
            expires: 30,
            sameSite: "Lax",
          });
          Cookies.set("rmeUser429", JSON.stringify(this.user), {
            expires: 30,
            domain: domain,
            sameSite: "Lax",
          });
          return true;
        })
        .catch((err) => {
          this.error = err?.response?.data?.message || err?.message;
          return false;
        });
      return result;
    },
    async register(email, name, pwd, cust_id) {
      const result = await axios
        .post("/auth/register", {
          name: name,
          email: email,
          password: pwd,
          stripe_customer_token: cust_id,
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return res.data;
        });
      if (result) return result;
    },
    async refresh() {
      const organizationStore = useOrganizationStore();
      await axios
        .get("/api/user/me")
        .then((res) => {
          this.user = res.data.user;
          organizationStore.subdomain = res.data.user?.owner?.subdomain;
          this.user.loggedin = true;
          return true;
        })
        .catch((err) => {
          return false;
        });
    },
    async logout() {
      this.user = { loggedin: false };
      //delete local storage
      localStorage.clear();
      localStorage.removeItem("user");
      localStorage.removeItem("organization");
      //localStorage.removeItem("subscription");
      //delete cookies
      const domain = extractDomain(window.location.href);
      Cookies.remove("rmeUser429", {
        expires: 30,
        domain: domain,
        sameSite: "Lax",
      });
      Cookies.remove("rmeSession429", {
        expires: 30,
        domain: domain,
        sameSite: "Lax",
      });
      return true;
    },
    async updatePassword(newPassword, oldPassword) {
      const result = await axios.post("/api/user/update-password", {
        password: newPassword,
        oldPassword: oldPassword,
      });
      if (result) return result;
    },
    //update user name
    async updateName(name) {
      //get current user id
      const userId = this.user._id;
      const result = await axios.post(`/api/user/${userId}/name`, {
        name: name,
      });
      if (result) return result;
    },

    async updateEmailSentDuration(duration) {
      const userId = this.user._id;
      const result = await axios.post(
        `/api/user/${userId}/email-sent-duration`,
        {
          duration: duration,
        }
      );
      if (result) return result;
    },
    //get support url
    async getSupportUrl() {
      const result = await axios.get("/auth/hubspot");
      if (result) return result;
    },
  },
  persist: {
    afterRestore: (ctx) => {
      try {
        const data = JSON.parse(Cookies.get("rmeUser429"));
        if (data.email && !ctx.store.$state.user?.email) {
          ctx.store.$state.user = data;
        }
      } catch {
        console.error("Error parsing user data");
      }
    },
  },
});
