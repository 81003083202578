import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import Particles from "vue3-particles";
import { VueColor } from "vue-color-next";
import VueTagsInput from "@james090500/vue-tagsinput";
//import RollbarPlugin from "./services/rollbar";
import VueGtag from "vue-gtag";
import VueScrollTo from "vue-scrollto";

import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";

import "./style.css";
import "./assets/vue-color-next/index.css";
import App from "./App.vue";
import router from "./router";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
} from "@fortawesome/vue-fontawesome";
import {
  faMagnifyingGlass,
  faMobileScreen,
  faDesktop,
  faPencil,
  faAngleDown,
  faAngleUp,
  faCircleInfo,
  faSquareCheck,
  faPaperclip,
  faXmark,
  faTrashCan,
  faPlusSquare,
  faBoxArchive,
  faPlus,
  faCopy,
  faDownload,
  faCloudArrowUp,
  faLock,
  faLockOpen,
  faLink,
  faList,
  faEnvelope,
  faInfoCircle,
  faMessage,
  faTags,
  faFile,
  faFileWord,
  faFilePdf,
  faFileZipper,
  faFilePowerpoint,
  faFileWaveform,
  faFileVideo,
  faFileImage,
  faFileCsv,
  faFileExcel,
  faFileText,
  faCirclePlus,
  faEye,
  faTriangleExclamation,
  faBell,
  faUnlock,
  faArrowUpWideShort,
  faArrowDownShortWide,
  faUserSecret,
  faShield,
  faLinkSlash,
  faFloppyDisk,
  faUpRightFromSquare,
  faArrowLeft,
  faCalendarXmark,
  faCheck,
  faFilterCircleXmark,
  faComment,
  faHashtag,
  faPlay,
  faPause,
  faRotateLeft,
  faLocationPin,
  faBold,
  faUnderline,
  faItalic,
  faListOl,
  faListUl,
  faUndo,
  faRedo,
  faQuoteRight,
  faStrikethrough,
  faSpinner,
  faFlask,
  faCircleArrowUp,
  faWandMagicSparkles,
  faCommentSlash,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faYoutube,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import {
  faUser,
  faBellSlash,
  faClock,
} from "@fortawesome/free-regular-svg-icons";

/* add icons to the library */
library.add(
  faMagnifyingGlass,
  faMobileScreen,
  faDesktop,
  faFacebook,
  faTwitter,
  faInstagram,
  faYoutube,
  faUser,
  faPencil,
  faAngleDown,
  faAngleUp,
  faCircleInfo,
  faSquareCheck,
  faPaperclip,
  faXmark,
  faTrashCan,
  faPlusSquare,
  faBoxArchive,
  faPlus,
  faCopy,
  faDownload,
  faCloudArrowUp,
  faLock,
  faLockOpen,
  faLink,
  faList,
  faEnvelope,
  faInfoCircle,
  faMessage,
  faTags,
  faFile,
  faFileWord,
  faFilePdf,
  faFileZipper,
  faFilePowerpoint,
  faFileWaveform,
  faFileVideo,
  faFileImage,
  faFileCsv,
  faFileExcel,
  faFileText,
  faFile,
  faCirclePlus,
  faEye,
  faTriangleExclamation,
  faBell,
  faBellSlash,
  faUnlock,
  faLockOpen,
  faArrowUpWideShort,
  faArrowDownShortWide,
  faUserSecret,
  faShield,
  faLinkSlash,
  faFloppyDisk,
  faUpRightFromSquare,
  faArrowLeft,
  faLinkedin,
  faCalendarXmark,
  faCheck,
  faFilterCircleXmark,
  faComment,
  faHashtag,
  faPlay,
  faPause,
  faRotateLeft,
  faLocationPin,
  faBold,
  faUnderline,
  faItalic,
  faListUl,
  faListOl,
  faUndo,
  faRedo,
  faQuoteRight,
  faStrikethrough,
  faSpinner,
  faFlask,
  faCircleArrowUp,
  faWandMagicSparkles,
  faCommentSlash,
  faClock,
  faCopy
);

const config = {
  AWS_SECRET_ACCESS_KEY: import.meta.env.VITE_AWS_SECRET_ACCESS_KEY,
  AWS_ACCESS_KEY_ID: import.meta.env.VITE_AWS_ACCESS_KEY_ID,
  AWS_REGION: import.meta.env.VITE_AWS_REGION,
  AWS_BUCKET_NAME: import.meta.env.VITE_AWS_BUCKET_NAME,
};

const app = createApp(App);
app.config.globalProperties.$config = config;
if (process.env.NODE_ENV !== "development") {
  // app.use(RollbarPlugin);
}
app.component("tags-input", VueTagsInput);
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("font-awesome-layers", FontAwesomeLayers);
app.component("splitpanes", Splitpanes);
app.component("pane", Pane);
app.use(Particles);
app.use(VueScrollTo);
app.use(VueColor);
app.use(pinia);
app.use(router);

//app.use(PasswordMeter);
app.use(
  VueGtag,
  {
    includes: [{ id: "G-2X09DQ1SJ1" }],
    config: { id: "UA-37047390-1" },
  },
  router
);
app.mount("#app");
