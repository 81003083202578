<template>
  <div v-if="error" class="my-auto mx-auto">
    <div class="card w-120 bg-slate-50">
      <div class="card-body items-center text-center">
        <h2 class="card-title">Oh no!</h2>
        <p>
          We encountered an issue. Please try one of the suggestions below. <br />
          If you still encounter the error please contact customer service. <br />
          <a href="mailto:support@reviewmyelearning.com">support@reviewmyelearning.com</a>
        </p>
        <div class="card-actions justify-end">
          <button class="btn btn-outline btn-neutral" @click="refresh">Refresh</button>
          <button class="btn btn-outline btn-success" @click="logout">Logout</button>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <slot></slot>
  </div>
</template>

<script setup>
import { onErrorCaptured, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useUserStore } from "../stores/userStore";

const route = useRoute();
const router = useRouter();
const userStore = useUserStore();
const error = ref(null);
const errorMessage = ref("");

onErrorCaptured((err, vm, info) => {
  if (process.env.NODE_ENV != "production" && process.env.NODE_ENV != "development") {
    error.value = err;

    errorMessage.value = info;
    return false; // Prevents the error from propagating further
  }
});

function refresh() {
  window.location.reload();
}

async function logout() {
  await userStore.logout();
  if (route.path != "/") {
    router.replace("/login");
  }
  window.location.reload();
}
</script>
